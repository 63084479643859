import { organization_endpoint, platform_users_endpoint, my_organization_endpoint } from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getOrganization = (id) => {
  return fetch(`${organization_endpoint}${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getOrganizationCertificates = (id) => {
  return fetch(`${organization_endpoint}${id}/certificates/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getOrganizationBadges = (id) => {
  return fetch(`${organization_endpoint}${id}/badges/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getOrganizationRewards = (id) => {
  return fetch(`${organization_endpoint}${id}/rewards/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getMyOrganizationMembers = () => {
  return fetch(`${my_organization_endpoint}members/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getPlatformUsers = (
  searchValue,
  organizationId,
  learningArea,
  careerPaths
) => {
  const params = new URLSearchParams({
    q: searchValue,
    organization: organizationId,
    topic: learningArea,
    career_path: careerPaths,
  });

  let keysForDel = [];
  params.forEach((value, key) => {
    if (value === "undefined") {
      keysForDel.push(key);
    }
  });

  keysForDel.forEach((key) => {
    params.delete(key);
  });

  return fetch(`${platform_users_endpoint}list/?` + params.toString(), {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const transferTokens = (data) => {
  return fetch(`${my_organization_endpoint}transfer_coins/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const organizationRepository = {
  getOrganization,
  getOrganizationCertificates,
  getOrganizationBadges,
  getOrganizationRewards,
  getMyOrganizationMembers,
  getPlatformUsers,
  transferTokens,
};
