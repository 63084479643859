import React, { useContext, useMemo } from "react";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { languages } from "../../../../dummyData/dummyData";
import Table from "../Table";
import { TYPE_DATE, TYPE_NUMBER } from "../../../molecules/Form/Form";
import { currencyFormat, dateFormat } from "../../../../utils/formatters";
import { licenseControlRepository } from "../../../../repository/licenseControl.repository";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../molecules/ProgressBar/ProgressBar";
import TokenSubRow from "./TokenSubRow";
import Text from "../../../atoms/Text/Text";
import { organizationRepository } from "../../../../repository/organization.repository";
import { getUserDisplayText } from "../LicensesTable/LicensesTable";
import styles from "./TokenTable.module.scss";

const addTokensPopupFields = (organizationName, data = {}) => [
  {
    name: "organization",
    label: languages.EN.labels.organization,
    disabled: true,
    value: organizationName,
  },
  {
    name: "total_coins",
    label: languages.EN.labels.amount,
    required: true,
    type: TYPE_NUMBER,
  },
  {
    name: "price",
    label: languages.EN.labels.totalPrice,
    required: true,
    type: TYPE_NUMBER,
  },
  {
    name: "expiry_date",
    label: languages.EN.labels.expiryDate,
    required: true,
    type: TYPE_DATE,
    value: data.expiry_date ?? "",
  },
];

const transferTokensPopupFields = (data = {}) => [
  {
    name: "num_coins",
    label: languages.EN.labels.amount,
    required: true,
    type: TYPE_NUMBER,
  },
];

const transferTokensFilterDefinitions = [
  {
    keyPath: "label",
  },
  {
    keyPath: "is_assigned",
    header: languages.EN.labels.isAssigned,
    typeOfFilter: "checkbox",
  },
];

const PopupRenderItem = ({ item }) => {
  return (
    <div className={styles.popupItem}>
      <span className={styles.popupItem__row}>
        <Text s12 dark-4 w800>
          {languages.EN.labels.licenseNumber}:
        </Text>
        <Text s12 dark-4>
          {item.name}
        </Text>
      </span>
      <span className={styles.popupItem__row}>
        <Text s12 dark-4 w800>
          {languages.EN.labels.assignedEmployee}:
        </Text>
        <Text s12 dark-4>
          {getUserDisplayText(item.assigned_user)}
        </Text>
      </span>
    </div>
  );
}

const TokenTable = ({ reports }) => {
  const { data, licenses, organizationId, organizationName, forPlatformOwner } = reports;
  const { callPopup } = useContext(PopupsContext);

  const items = data.map((item) => ({
    ...item,
    subRow: !forPlatformOwner ? (
      <TokenSubRow
        data={item}
      />
    ) : undefined,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.createdOn,
      accessor: "created",
      Cell: (data) => dateFormat(data.row.original.created),
    },
    {
      Header: languages.EN.labels.transactionNumber,
      accessor: "number",
    },
    {
      Header: languages.EN.labels.totalPrice,
      accessor: (data) => currencyFormat(data.price),
    },
    {
      Header: languages.EN.labels.boughtTokens,
      accessor: "total_coins",
    },
    {
      Header: languages.EN.labels.availableTokens,
      accessor: "available_coins",
      Cell: (data) => (
        <ProgressBar
          variant={VARIANT_TABLE_PRIMARY}
          progress={data.row.original.available_coins / data.row.original.total_coins * 100}
          information={`${data.row.original.available_coins}/${data.row.original.total_coins}`}
        />
      ),
    },
    {
      Header: languages.EN.labels.expiryDate,
      accessor: (data) => dateFormat(data.expiry_date),
    },
  ], []);

  return <Table buttons={forPlatformOwner ? [
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.tokens}`,
          fields: addTokensPopupFields(organizationName),
          postAction: (formData) => licenseControlRepository.addCoins({ ...formData, organization: organizationId }),
        },
      }),
    },
  ] : [
    {
      icon: "url",
      label: languages.EN.labels.transferTokens,
      onClick: () => callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: languages.EN.labels.selectLicenses,
          items: licenses.filter((license) => license.is_token).map((item) => ({
            ...item,
            label: item.name + ' ' + getUserDisplayText(item.assigned_user),
            is_assigned: item.assigned_user !== null ? languages.EN.labels.assigned : languages.EN.labels.notAssigned,
          })),
          RenderItem: PopupRenderItem,
          filters: transferTokensFilterDefinitions,
          singleSelect: false,
          onSave: (formData) => {
            callPopup({ type: ACTIONS.REMOVE });
            callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: languages.EN.labels.transferTokens,
                fields: transferTokensPopupFields(),
                postAction: (transferFormData) => organizationRepository.transferTokens({
                  target_licenses: formData.join(','),
                  ...transferFormData,
                })
              }
            });
          },
        },
      }),
    },
  ]} columns={columns} data={items} expand={!forPlatformOwner ? false : undefined} />;
}

export default TokenTable;
